
.header__inline-menu .drawer-menu-open:hover svg[data-withouthover],
.header__inline-menu .drawer-menu-open svg[data-withonhover]{display: none !important;}
.header__inline-menu .drawer-menu-open:hover svg[data-withonhover]{display: flex !important;}
.variant-wrapper {
    border: 1px solid #00000066;
    width: 100%;
    border-radius: 20px;
    padding: 30px;
}
.product-form__quantity {
    display: flex;
    align-items: center;
}
.product-form__input input[name="Color"]:checked + label,
.product-form__input input[name="Colour"]:checked + label,
.product-form__input input[name="Couleur"]:checked + label

{
    outline: 1px solid #0187A7;
    border: 2px solid #EEEEDC;
}
.product-form__input input[name="Color"] + label,
.product-form__input input[name="Couleur"] + label
{
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.25);
}

.product-form__input input[name='Memory']:checked + label,
.product-form__input input[name='Mémoire']:checked + label
{
    background: #0187A7;
}


.product-form__input input[name='Memory'] + label,
.product-form__input input[name='Mémoire'] + label 
{
    background: #EEEEDC;
    color: #0187A7;
    border-radius:10px !important;
text-align: center;
font-family: Panton;
font-size: 15px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.form__option--memory {
    display: flex !important;
    flex-direction: column;
}
.form__option--memory span{
    font-weight: 400;
}
.product-form__input input[name='Memory']:checked + label {
    background: #0187A7;
    color: white;
}
.product-form__input input[name='Mémoire']:checked + label {
    background: #0187A7;
    color: white;
}
.product-form__input input[name='Condition']:checked + label {
    background: #0187A7;
}
.product-form__input input[name='Condition'] + label {
    background: #EEEEDC;
    color: #0187A7;
    border-radius:10px !important;
    text-align: center;
    font-family: Panton;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    min-width: 119px !important;
}
.product-form__input input[name='Condition']:checked + label {
    background: #0187A7;
    color: white;
}
.warranty-module-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 18px;
    font-family: 'Panton';
    font-weight: 800;
    color: #0187A7;
    line-height: 21.6px;
    margin-top: 30px;
}

.warranty-module-wrapper .knobs,
.warranty-module-wrapper .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
input#warrantyProductChecked:not(:checked) ~ .layer  {
background-color: #CDD1D1;
}
.warranty-module-wrapper .check-button {
  position: relative;
  top: 50%;
  width: 60px;
  height: 25px;
  overflow: hidden;
}

.warranty-module-wrapper .check-button.r,
.warranty-module-wrapper .check-button.r .layer {
  border-radius: 100px;
  width: 50px;
  min-width: 50px;
}

.warranty-module-wrapper .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.warranty-module-wrapper .knobs {
  z-index: 2;
}

.warranty-module-wrapper .layer {
  width: 100%;
  background-color: #0187A7;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.warranty-module-wrapper #button-1 .knobs::before {
  content:"";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 0;
  padding: 9px 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  display: flex;
  align-items: center;
}

.warranty-module-wrapper #button-1 .checkbox:checked + .knobs::before {
    content: "";
    font-family: 'Titillium Web';
    left: 26px;
    background-color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warranty-module-wrapper #button-1 .checkbox:checked ~ .layer {
  background-color: #0187A7;
}

.warranty-module-wrapper #button-1 .knobs,
.warranty-module-wrapper #button-1 .knobs::before,
.warranty-module-wrapper #button-1 .layer {
  transition: 0.3s ease all;
}

#Details-HeaderMenu-3 #MegaMenu-Content-3{
    max-width: 363px;
    padding: 0;
}
#Details-HeaderMenu-3 #MegaMenu-Content-3 ul {
    padding: 0;
}
#Details-HeaderMenu-3 #MegaMenu-Content-3 ul a {
    color: #0187A7;
    font-family: 'Titillium Web';
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    width: 100%;
    display: flex;
    padding-left: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    align-items: center;
    gap: 10px;
}
#Details-HeaderMenu-3 #MegaMenu-Content-3 ul a:hover {
    text-decoration: underline;
}

.product-form__buttons {
    border-radius: 5px;
    color: #0187A7;
text-align: center;
font-family: Panton;
font-size: 25px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
legend.form__label {
    color: #0187a7;
    font-family: Panton;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    gap: 10px;
    align-items: end;
}
span.deselect {
    border-radius: 30px;
    border: 1.5px solid #0187A7;
    background: rgba(238,238,220,0);
    color: #0187a7;
    text-align: center;
    font-family: 'Titillium Web';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    padding: 0 6px;
    cursor: pointer;
    position: relative;
    top: -2px;
}
span.deselect.condition-deselect {
    border: unset;
    text-decoration: underline;
}
.quantity__button:not(:focus-visible):not(.focused), .quantity__input:not(:focus-visible):not(.focused) {
    background-color: transparent;
}
.quantity__button {
    width: 20px;
}
.quantity {
    width: 100%!important;
    min-height: 41px;
    padding: 0 4px;
}
.quantity__input {
    max-width: 30px;
    width: 100% !important;
    color: #0187A7;
    text-align: center;
    font-family: Panton;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.product-form__quantity {
    gap: 20px;
}
.product-form__input input[type=radio]:disabled+label, .product-form__input input[type=radio].disabled+label {
    color: #BCB5AF !important;
    text-decoration: unset !important;
    background:transparent;
    border: 1px solid #BCB5AF;
    pointer-events: none;
}
.label-list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.product__title h1 {
    color: #0187A7;
    font-family: "Panton";
    font-size: 40px;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 1;
}
.caption-with-letter-spacing {
    color: #0187A7;
    font-family: 'Titillium Web';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: unset !important;
}

label.quantity__label.form__label {
    color: #0187A7;
    font-family: Panton;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
quantity-input.quantity {
    border-radius: 10px;
    background: #EEEEDC;
}
.facets__header-vertical {
    display: none;
}
.active-facets.active-facets-desktop {
    display: none;
}
legend.form__label.label-wrapper-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.quantity__button {
    color: #0187A7;
    text-align: center;
    font-family: Panton;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
/* Collection Styling*/
.collection-hero {
    background: #0187A7 !important;
}
h1.collection-hero__title {
    color: #eeeedc;
    font-family: Panton;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -.475px;
    margin: 0;
    padding: 0;
}
.custom-description {
    color: #eeeedc;
    font-family: 'Titillium Web';
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    display: block;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 10px 0 !important;
}
.collection-wrapper {
    background: #EEEEDC;
}
.product-grid {
    column-gap: 44px;
    row-gap: 50px;
}
.main-product {
    max-width: 1440px;
}

.card-wrapper {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}
.product-grid {
    column-gap: 44px;
    row-gap: 50px;
    width: 100%;
    display: flex;
}
.product-grid li.grid__item {
    flex: 0 0 28%;
}
.card__badge {
    justify-self: flex-end;
    position: absolute;
    top: -21px;
    right: 0;
}
.card__heading a:hover {
    text-decoration: none !important;
}
.badge {
    /* background-color: #fff000; */
    border-radius: 20px;
    box-shadow: 0 4px 4px #00000040;
    /* color: #0187A7; */
    font-family: Panton;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
li {
    list-style: none;
}
li a {
    text-decoration: none;
}
.category-link{
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.22px;
}
.category-link.bold {
    font-weight: 700;
}
ul.parent {
    margin: 0;
    padding: 0;
}

.facet-checkbox span {
    z-index: 1;
    white-space: nowrap;
}

.collection-hero__inner {
    flex-direction: column!important;
    align-items: center!important;
    /* margin: 0 auto;
    max-width: 1110px; */
}
.collection-hero__text-wrapper {
    align-self: flex-start;
}
.collection-hero__navigation {
    display: flex;
    gap:35px;
    margin-bottom: 45px;

}
.collection-hero__navigation--inner {
    background: #EEEEDC;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    border-radius: 20px;
    justify-content: space-between;
}
.collection-hero__inner {
    padding-top: 40px;
    padding-bottom: 40px !important;
}

.collection-hero__text-wrapper {
    padding-bottom: 15px;
}
h1.collection-hero__navigation--inne-title {
    color: #000;
    text-align: left;
    font-family: "Panton";
    font-size: 25px;
    font-style: normal;
    padding-left: 15px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.275px;
}
.noUi-horizontal {
    height: 9px !important;;
}
.noUi-horizontal .noUi-handle {
    width: 18px !important;;
    height: 18px !important;;
    top: -6px !important;;
    background: black  !important;;
}
.noUi-handle {
    border: 5px solid #D9D9D9 !important;;
    border-radius: 42px !important;;
    background: transparent !important;;
    box-shadow: unset !important;;
}
.noUi-handle:after, .noUi-handle:before {
    content: unset !important;
}

.facets-wrap-vertical.Model {
    height: fit-content;
    overflow-y: auto;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,.4);
    background: #eeeedc;
    padding: 12px;
}

 
ul {
    padding-inline-start: 20px;
}

aside#main-collection-filters {
    flex: 0 0 33%;
}

ul.grandchild {
    max-height: 300px;
    overflow-y: scroll;
}
.Memory .list-unstyled , .Mémoire .list-unstyled{
    display: flex;
    flex-wrap: wrap;
}
.Memory .facets__item,.Mémoire .facets__item {
    display: flex;
    align-items: center;
    /* flex: 0 0 33%; */
    width: fit-content;
}
.Condition .facets__item {
    display: flex;
    align-items: center;
    flex: 0 0 33%;
    width: 100%;
}
.facets-vertical .facets__summary {
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.253px;
}
label.facet-checkbox {
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 20px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.22px;
}


.breadcrumbs-wrapper.breadcrumbs-active svg,.breadcrumbs-child-active-child svg,.breadcrumbs-grandchild-active svg {
    display: none;
}
/* Mobile Filtering style*/


.mobile-facets__header-inner {
    text-align: left;
}
.mobile-facets__wrapper {
  
}



ul.child-mobile,.parent-mobile{
    display: flex;
    padding-inline-start: 0;
}
ul.grandchild-mobile {
    display: flex;
    padding-inline-start: 0;
}
li.grandchild-links,
li.child-links:not(.active-child-mobile){
    border-radius: 5px;
    background: #EEEEDC;
    margin-right: 5px;
}
a.grandchild-link-mobile.category-link-mobile,
a.child-link-mobile.category-link-mobile{
    width: max-content;
    color: #0187A7;
    font-family: Panton;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.165px;
    padding: 5px 10px;
    border-radius: 8px;
}
/* .btn-hover:hover {
    opacity: 0.5;
} */
.breadcrumbs-active {
    display: flex!important;
}
.breadcrumbs-child.breadcrumbs-child-active-child, .breadcrumbs-grandchild-active {
    display: flex!important;
}
.breadcrumbs-wrapper {
    display: flex;
    align-items: center;
    gap:10px;
}
.breadcrumbs-child {
    gap:10px;
}
.breadcrumbs ul {
    padding-inline-start: 0;
}
a.breadcrumbs-parent, .breadcrumbs-child-link {
    color: #EEEEDC;
    font-family: 'Titillium Web';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.11px;
    text-decoration: none;
}
.breadcrumbs {
    display: flex;
    justify-content: left;
    width: 100%;
    gap:10px;
}

.breadcrumbs-wrapper a {
    color: #EEEEDC;
font-family: 'Titillium Web';
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
gap:10px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.breadcrumbs-wrapper svg {
    width: 13px;
    height: 9px;
}
.collection-hero__description.rte {
    color: #EEEEDC;
    font-family: 'Titillium Web';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.228px;
    width: 100%;
    max-width: 100%;
}

.card__heading {
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.22px;
    margin: 0;
}
span.price-item  span {
    font-weight: 400;
}
ul.list-unstyled.no-js-hidden.list-Model {
    max-height: 210px;
    overflow: auto;
    max-width: 260px;
}
span.price-item {
    display: block ;
    flex-direction: column;
    color: #1E1E1E;
font-family: 'Titillium Web';
font-size: 14px;
font-style: normal;
/* font-weight: 800; */
line-height: 150%; /* 18px */
letter-spacing: -0.132px;
}
.xlabel {
    padding: 5px 10px;

}
.facet-checkbox input[type=checkbox]:checked + .xlabel {
    background: #fff;
    z-index: 1;
    position: relative;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
}
s.price-item.variant-item__old-price {
    font-size: 20px;
    color: #0187A7;
}
.header__menu-item span {
    color: #0187A7;
    font-family: "Panton";
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.header__menu-item:hover {
   text-decoration: underline !important;
  text-decoration-color: #0387a7 !important;
  text-underline-offset: 10px; 
}
nav.header__inline-menu span {
    display: flex;
    cursor: pointer;
}
a.pagination__item {
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.22px;
}
.light {
    opacity: 1;
    text-decoration: none !important;
    background: white;
    border-radius: 10px;
}
.pagination__item--current:after{
    display: none !important;
}
.localization-selector svg {
    display: none;
}
button.disclosure__button span {
    color: #0187A7;
    font-family: 'Titillium Web';
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

a:not([href]) {
    cursor: pointer;
}
.header__menu-item:hover span {
    text-decoration: none;
    text-underline-offset: .3rem;
}

span.drawer-menu-close, span.drawer-menu-open {
    width: 30px;
}

.desktop-contact-phone__pop.header-call {
    position: absolute;
    top: 68px;
    right: 80px;
    flex-direction: column;
    max-width: 350px;
    background: #EEEEDC;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 19px;
    padding: 20px;
}
span.desktop-contact-phone__pop__text {
    color: #000;
    font-family: 'Titillium Web';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
span.desktop-contact-phone__pop__phone {
    color: #000;
    font-family: Panton;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
span.desktop-contact-phone__pop__phone p {
  margin: 0;
}
span.desktop-contact-phone__pop__phone p a {
  color: unset !important;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}
.menu-3 .links-wrapper.innerlinks-3 .drawer-custom-grandchildlink {
    flex: 1 1 33%;
}
.meta-wrapper {
    display: flex;
    flex-direction: column;
}
span.card-information__meta {
    color: #1E1E1E;
    font-family: 'Titillium Web';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.132px;
    width: 100%;
    display: block;
}
.card-information__meta.storage{
    word-spacing: 5px;
    font-size: 14px;
}
.design-scrollbar::-webkit-scrollbar {
  width: 20px;
}
.design-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.design-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 1);
}
.design-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 20px;
}
.design-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.breadcrumbs-pdp-wrapper{
    display: flex;
    align-items: center;
    gap:17px;
    margin-top:15px;
}
.breadcrumbs-pdp {
    color: #0187a7;
    font-family: 'Titillium Web';
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    gap: 17px;;
    display: flex;
    align-items: center;
}
a.breadcrumbs-pdp svg {
    display: block;
    width: 10px;
}
.no-variant-selected {
    color: #0187A7;
    font-family: Panton;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px;
}
.facet-checkbox input[type=checkbox]:checked+.xlabel::after,
.mobile-facets__item input[type=checkbox]:checked+.mobile-Model-label::after{
    content: '\00D7';
    margin-left: 10px;
    background: #fff;
    height: 30px;
    width: 30px;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    position: relative;
    top: -1px
}
.mobile-facets__item input[type=checkbox]:checked+.mobile-Model-label::after{
  height: 20px;
  width: 20px;
}
ul.list-unstyled.no-js-hidden.list-Condition {
    display: flex;
    flex-direction: column;
}
ul.list-unstyled.no-js-hidden.list-Condition .order-1,
.gradient.mobile-labels.Condition .order-1{
    order: 3;
}
ul.list-unstyled.no-js-hidden.list-Condition .order-2 {
    order: 1;
}
ul.list-unstyled.no-js-hidden.list-Condition .order-3 {
    order: 2;
}
[data-regular-price]{
  font-size: 20px;
  color: #0387a7;
  font-weight: 800;
  text-align: center;
}
[data-compare-price]{
  font-size: 20px;
  color: #0387a7;
  text-align: center;
}
[data-compare-price] span{text-decoration: line-through;}
#trustpilot-widget-trustbox-0-wrapper{z-index: 1 !important;}
.product-form__submit.button{
  background-color: #FFF000 !important;
  border-radius: 5px;
}
.product-form__submit.button:hover{
  background-color: #FFF00099 !important;
}
.gradient.mobile-labels.Model::-webkit-scrollbar-corner,
ul.list-unstyled.no-js-hidden.list-Model::-webkit-scrollbar-corner{
    background: transparent;
}

a.show-comb {
    color: #0187A7;
    font-family: 'Titillium Web';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-top: 18px;
    display: block;
}
@media screen and (max-width:990px){
    a.show-comb {
        font-size: 15px;
    }
    .desktop-contact-phone__pop.header-call {
        top: 93px;
        right: 10px;
    }
    span.desktop-contact-phone__pop__text,
    span.desktop-contact-phone__pop__phone{
        font-size: 15px;
    }
    ul.child-mobile.child-active-mobile .child-links {
        display: none !important;
    }
    ul.child-mobile.child-active-mobile .child-links.active-child-mobile {
      display: flex !important;
    }
    ul.child-mobile.child-active-mobile .child-links.active-child-mobile > a {
      display: none;
    }
    .cart-items .cart-item {
        padding: 5px 0 !important;
    }

    .breadcrumbs-pdp-wrapper{
      gap: 12px;
    }
    .breadcrumbs-pdp {
      font-size: 14px;
      gap: 10px;
    }
    a.breadcrumbs-pdp svg {
      width: 6px;
    }
    li.grid__item {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .card__content {
        display: flex;
        width: 100%;
    }
    .collection-hero__navigation {
        display: none;
    }
    .card:not(.ratio) {
        flex-direction: row !important;

    }
    ul.child-mobile {
        gap: 10px;
    }
    ul.parent-mobile {
        padding-inline-start: 0;
        display: flex;
        gap:10px;
        overflow-x: scroll;
        padding: 20px 10px 20px 50px;
    }
    li.parent-li {
        display: flex;
    }
    li.parent-li a {
        display: flex;
        align-self: center;
        color: #0187a7;
        font-family: Panton, Arial;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: -.165px;
        background: #eeeedc;
        padding: 5px 10px;
        width: max-content;
    }
    li.child-links, a.parent-link-mobile{

        /* background: #EEEEDC; */
    border-radius: 5px;
    /* padding: 5px 11px; */
    align-items: center;
    }
    .child-link-mobile {
        width: max-content;
        display: flex;
        align-self: center;
        color: #0187A7;
        font-family: Panton;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: -0.165px;
    }
    .breadcrumbs-wrapper a {
        font-size: 10px;
        font-weight: 400;

    }
    .breadcrumbs-wrapper svg {
        width: 8px;
        height: 5px;
    }
    h1.collection-hero__title {
        font-size: 20px;
    }
    menu-drawer.mobile-facets__wrapper.medium-hide.large-up-hide {
        border-radius: 5px;
        background: #0187A7;
        color: #EEEEDC;
        text-align: center;
        font-family: Panton;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 2;
        justify-content: center;
        display: block;
    }
    .collection-hero__inner {
        padding-bottom: 0 !important;
    }
    ul.parent-mobile {
        padding-top: 0px;
        margin: 0;
    }
    .card__badge {
        top: -25px;
        right: -100%;
    }
    .header-email {
        display: none !important;
    }
    .menu-drawer__menu-item {
        color: #0187A7 ;
        font-family: Panton, Arial;
        font-size: 25px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        justify-content: space-between;
    }
    button.menu-drawer__close-button {
        color: #0187a7;
        font-family: Panton;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        margin: 0;
        line-height: normal;
        flex: 1 1 10%;
        padding: 0;
        width: 100%;
        background: transparent;
    }
    .menu-drawer-mobile {
        display: flex;
        align-items: center;
        padding: 30px 20px;
    }
    div#menu-drawer {
        top: 0;
        height: 100vh;
    }
    .mobile-nav {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        align-items: center;
        padding: 0 30px;
        padding-bottom: 30px;
    }
    .menu-drawer-mobile span {
        color: #0187a7;
        font-family: Panton;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .header__heading-logo-wrapper {
        width: 100%;
        display: flex;
        transition: width .3s cubic-bezier(.52,0,.61,.99);
        justify-content: center;
        flex: 1 1 70%;
    }
    span.drawer-menu-close, span.drawer-menu-open {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex: 1 1 10%;
    }
    .menu-drawer summary.menu-drawer__menu-item {
        justify-content: space-between ;
    }
    .menu-drawer__menu-item {
        padding: 1.1rem 20px;
       
    }
    .mobile-contact {
        display: flex;
        align-items: center;
        gap:27px;
    }
    .menu-drawer__utility-links {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        z-index:2;
        top: auto;
        bottom: 0px;
    }
    .mobile-contact-phone__pop {
        position: absolute;
        top: -75px;
        right: 30px;
        flex-direction: column;
        background: #eeeedc;
        max-width: 235px;
        opacity: .9;
        filter: drop-shadow(0px 4px 4px rgba(0,0,0,.25));
        border-radius: 16px;
        padding: 18px 15px;
    }
    span.mobile-contact-phone__pop__text {
        color: #000;
        font-family: 'Titillium Web';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    span.mobile-contact-phone__pop__phone {
        color: #000;
        font-family: Panton;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    .mobile-drawer__icon-menu{
        display: flex;
        align-items: center;
        gap: 17px;
    }
    span.mobile-drawer__icon-menu svg {
        width: 27px;
    }
    ul.menu-drawer__menu.list-menu {
        max-height: fit-content;
        overflow-y: scroll;
    }
    svg.arrow-icon {
        display: flex;
        visibility: visible;
    }
    span.mobile-drawer__icon-menu.drawer-up:before {
        content: '';
        width: 27px;
    }
    .mobile-facets__footer {
        display: flex;
        flex-direction: column-reverse !important;
        width: 100%;
        gap:25px;
        margin:40px  0;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        padding: 0 10px;

    }
    .mobile-facets__footer>* {
        width: 100%!important;
        border-radius: 5px;
        background: #0187A7;
        color: #EEEEDC;
        text-align: center;
        font-family: Panton;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
    }
    .mobile-facets__footer>*+* {
        margin-left: 0 !important;
    }
    h2.mobile-facets__heading.medium-hide.large-up-hide {
        color: #1E1E1E;
        font-family: Panton;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: -0.22px;
    }
    label.mobile-facets__label {
        color: #1E1E1E;
        font-family: 'Titillium Web';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.165px;
        white-space: nowrap;
    }
    summary.mobile-facets__summary.focus-inset span {
        color: #1E1E1E;
        font-family: Panton;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
        letter-spacing: -0.22px;
    }
    .mobile-labels {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-left: 23px;
    }
    .mobile-facets__details {
        max-width: 240px;
    }
    .mobile-facets__checkbox:checked + .mobile-Memory-label  {
        border-radius: 10px;
        background: #FFF !important;
        z-index: 9;
    }
    .mobile-facets__checkbox:checked + .mobile-Condition-label {
        border-radius: 10px;
        background: #FFF !important;
        z-index: 9;
    }
    .mobile-Memory-label, .mobile-Condition-label {
        padding: 0px 12px;
    }
    .product-grid li.grid__item {
        flex: 0 0 100%;
    }
    .Availability {
        display: none;
    }
    .product-grid {
        row-gap: 15px;
    }
    .mobile-facets__footer button {
        border: unset;
        height: 30px;
    }
    .mobile-facets__footer facet-remove {
        height: 30px;
    }
    .mobile-facets__footer facet-remove a{
        text-decoration: none;
    }
    .gradient.mobile-labels.Model {
        border: 1px solid rgba(0,0,0,.4);
        background: #eeeedc;
        border-radius: 10px;
        max-height: 210px;
        overflow: auto;
        padding: 13px;
        display: flex;
    }
    .gradient.mobile-labels.Model .list-menu__item {
      width: 100%;
    }
    .facets__price {
        display: flex;
        margin-left:23px;
      }
     
}
@media screen and (max-width:750px){
  ul.parent-mobile {
    padding: 20px 10px 20px 20px;
    max-height: 72.5px;
  }
  .collection-hero {
    min-height: 216px;
}
}
section#shopify-section-template--22269240475958__1705696078f5cdcb28 {
    min-height: 540px;
}